import "./style.css";
import * as THREE from "three";
import * as dat from "lil-gui";
import { CSG } from "three-csg-ts";
// import Stats from "three/examples/jsm/libs/stats.module";

/**
 * Användbart: https://www.npmjs.com/package/three-csg-ts
 * https://github.com/manthrax/THREE-CSGMesh
 *
 * Hail mary-knapp som saktar ner spelet en gång
 *
 * Biten som trillar av kan vara ett fysikobjekt.
 */

/**
 * 1. Skapa den första boxen i stapeln.
 * 1.1 Skapa en osynlig box (intersectionBox) av samma storlek och placera ovanpå den första boxen.
 * 2. Skapa den rörliga boxen (movingBlock) och placera den på sin ursprungsposition.
 * 3. OnClick ->
 *    Om spelet inte startat -> starta spelet
 *    Om spelet startat ->
 *      Skapa en ny mesh från intersection mellan movingBlock och intersectionBox.
 *      Om intersection existerar ->
 *        Sätt storleken på intersectionBox till intersection och flytta i y-led med boxThickness
 *        Skapa en ny BoxGeometry med samma storlek som intersectionBox och pusha till stack.
 *        Sätt storleken på movingBlock till intersection
 *        Flytta movingBlock till nästa orientation
 *        Aligna movingBlock till det översta blocket
 *      Annars -> game over
 * 4. tick() ->
 *    Animera movingBlock enligt currentDirection (använd Greensock?)
 *    Om den bakre änden av movingBlock passerar över den översta boxen i stacken -> game over
 *
 */

let highscore = parseInt(localStorage.getItem("highscore"), 10) || 0;

const scoreCard = document.querySelector("#score");
const highscoreCard = document.querySelector("#highscore");
const menu = document.querySelector("#menu");
highscoreCard.innerText = highscore;

/**
 * Debug
 */
const gui = new dat.GUI();
gui.open(false);

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Colors
const colors = {
  background: 0x80d9ff,
  ambientLight: 0x3d3d3d,
  directionalLight: "0xffffff",
};

// Scene
const scene = new THREE.Scene();
let score;
let movingBlock;
let intersectionBlock;
let movingBlockBox3;
let intersectionBlockBox3;
let overhangBlocks;
let currentHeight;
let gameRunning = false;
let boxThickness = 0.2;
let currentDirection;
let startBlock;
let boxGroup = new THREE.Group();
let newCameraHeight;
scene.add(boxGroup);

scene.background = new THREE.Color(colors.background);

/**
 * Lights
 */
var ambientLight = new THREE.AmbientLight(colors.ambientLight);
scene.add(ambientLight);

var directionalLight = new THREE.DirectionalLight(colors.directionalLight);
directionalLight.position.set(1, 0.75, 0.5).normalize();
scene.add(directionalLight);

gui.addColor(colors, "background").onChange(() => {
  scene.background.set(colors.background);
});
gui.addColor(colors, "ambientLight").onChange(() => {
  ambientLight.color.set(colors.ambientLight);
});
gui.addColor(colors, "directionalLight").onChange(() => {
  directionalLight.color.set(colors.directionalLight);
});

function initGame() {
  score = 0;
  camera.position.set(2.5, 2.5, 2.5);
  newCameraHeight = 2.5;
  currentHeight = boxThickness;
  currentDirection = "east";

  startBlock = new THREE.Mesh(
    new THREE.BoxGeometry(1, boxThickness, 1),
    new THREE.MeshLambertMaterial({ color: 0xff0000 })
  );

  boxGroup.add(startBlock);

  movingBlock = new THREE.Mesh(
    new THREE.BoxGeometry(1, boxThickness, 1),
    new THREE.MeshLambertMaterial({ color: 0x00ff00 })
  );

  movingBlock.position.y = currentHeight;
  movingBlock.position.x = 3;
  boxGroup.add(movingBlock);

  intersectionBlock = new THREE.Mesh(new THREE.BoxGeometry(1, boxThickness, 1));
  intersectionBlock.position.y = currentHeight;

  overhangBlocks = [];

  // movingBlockBox3 = new THREE.Box3().setFromObject(movingBlock);
  // intersectionBlockBox3 = new THREE.Box3().setFromObject(intersectionBlock);

  canvas.removeEventListener("click", handleClick);
  canvas.addEventListener("click", handleClick);

  scoreCard.innerText = score;
}

menu.addEventListener("click", function () {
  while (boxGroup.children.length > 0) {
    boxGroup.remove(boxGroup.children[0]);
  }
  menu.classList.toggle("hidden");
  initGame();
  gameRunning = true;
});

// Axes helper
// const axesHelper = new THREE.AxesHelper(2);
// scene.add(axesHelper);

function getRandomColor() {
  return new THREE.Color(Math.random() * 0xffffff);
}

function handleClick() {
  if (gameRunning) {
    // Check if movingBlock and intersectionBlock are intersecting. If not, game over.
    movingBlockBox3 = new THREE.Box3().setFromObject(movingBlock);
    intersectionBlockBox3 = new THREE.Box3().setFromObject(intersectionBlock);
    var intersects = movingBlockBox3.intersectsBox(intersectionBlockBox3);

    if (!intersects) {
      console.log("game over");
      gameRunning = false;
      menu.classList.toggle("hidden");
      if (score > highscore) {
        localStorage.setItem("highscore", score);
        highscore = score;
        highscoreCard.innerText = highscore;
      }
      return;
    }

    movingBlock.updateMatrix();
    intersectionBlock.updateMatrix();

    const newOverhangBlock = CSG.subtract(movingBlock, intersectionBlock);
    newOverhangBlock.material = movingBlock.material.clone();
    boxGroup.add(newOverhangBlock);
    overhangBlocks.push(newOverhangBlock);

    const newBlock = CSG.intersect(movingBlock, intersectionBlock);

    newBlock.material = movingBlock.material.clone();

    newBlock.position.y = currentHeight;
    boxGroup.add(newBlock);
    score++;
    scoreCard.innerText = score;

    intersectionBlock.geometry = newBlock.geometry.clone();
    intersectionBlock.position.copy(newBlock.position);
    intersectionBlock.position.y = currentHeight + boxThickness;

    movingBlock.geometry = newBlock.geometry.clone();
    movingBlock.position.copy(newBlock.position);
    movingBlock.position.y = currentHeight + boxThickness;
    movingBlock.material.color = getRandomColor();

    currentDirection = getNextDirection(currentDirection);
    if (currentDirection === "east") {
      movingBlock.position.x = 3;
    } else if (currentDirection === "south") {
      movingBlock.position.z = -3;
    } else if (currentDirection === "west") {
      movingBlock.position.x = -3;
    } else {
      movingBlock.position.z = 3;
    }

    currentHeight += boxThickness;

    newCameraHeight += boxThickness;
  } else {
    gameRunning = true;
  }

  console.log("score", score);
}

function getNextDirection(direction) {
  switch (direction) {
    case "north":
      return "east";
    case "east":
      return "south";
    case "south":
      return "west";
    default:
      return "north";
  }
}

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height);
camera.position.set(2.5, 2.5, 2.5);
scene.add(camera);

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas,
  antialias: true,
});
renderer.setSize(sizes.width, sizes.height);

camera.lookAt(-55.5, -55.5, -55.5);

const gameVars = {
  movementSpeed: 2,
  fallingSpeed: 4,
};

gui.add(gameVars, "movementSpeed", 0.1, 4, 0.1);
gui.add(gameVars, "fallingSpeed", 0.1, 6, 0.1);

initGame();

// const stats = Stats();
// document.body.appendChild(stats.dom);

const clock = new THREE.Clock();

const tick = () => {
  renderer.render(scene, camera);
  // stats.update();

  const timeDelta = clock.getDelta();

  if (gameRunning) {
    if (currentDirection === "east") {
      movingBlock.position.x -= gameVars.movementSpeed * timeDelta;
    } else if (currentDirection === "south") {
      movingBlock.position.z += gameVars.movementSpeed * timeDelta;
    } else if (currentDirection === "west") {
      movingBlock.position.x += gameVars.movementSpeed * timeDelta;
    } else {
      movingBlock.position.z -= gameVars.movementSpeed * timeDelta;
    }

    for (let i = 0; i < overhangBlocks.length; i++) {
      overhangBlocks[i].position.y -= gameVars.fallingSpeed * timeDelta;
    }

    if (camera.position.y < newCameraHeight) {
      camera.position.y += gameVars.movementSpeed * timeDelta;
    }

    // Animera mha. https://threejs.org/docs/#api/en/extras/curves/CubicBezierCurve3
    // Baserat på currentDirection, rotera kurvan runt y.
    // Sätt overhangBlocks position från kurvans punkter
  }

  window.requestAnimationFrame(tick);
};

tick();
